// Estilos globales
@import '../../styles.scss';

header {
    justify-content: space-between;
    .header--left-side {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        @media screen and (max-width: $tablet-breakpoint) {
            width: 100%;
            justify-content: space-between;
        }
        .mdc-evolution-chip-set {
            margin-left: 30px;
            @media screen and (max-width: $laptop-breakpoint) {
                margin-left: 10px;
            }
            .mdc-evolution-chip {
                margin-left: 30px;
                @extend .mdc-evolution-chip--high-emphasis;
                .material-symbols-outlined {
                    font-size: 20px;
                    margin-right: 8px;
                    margin-left: 4px;
                }
            }
        }
        .list-hidden-portion {
            @media screen and (min-width: $tablet-breakpoint) {
                display: none;
            }
        }
    }
    .header--right-side {
        display: flex;
        flex-flow: row nowrap;
        @media screen and (max-width: $tablet-breakpoint) {
            display: none;
        }
        a {
            margin-left: 10px;
        }
    }
}

.landing-welcome {
    padding-bottom: 50px;
    .decoration-10 {
        width: 33px;
        position: absolute;
        margin-top: 3px;
        margin-left: 700px;
        @media screen and (max-width: $laptop-breakpoint) {
            width: 30px;
            margin-left: 455px;
            @media screen and (max-width: $tablet-breakpoint) {
                width: 28px;
                margin-left: 410px;
                @media screen and (max-width: $mobile-breakpoint) {
                    width: 23px;
                    margin-left: 315px;
                }
            }
        }
    }
    .headline-xl {
        white-space: nowrap;
        .headline-lx__words_spinner {
            display: inline-block;
            min-width: 350px;
            text-align: left;
            span {
                margin-top: -60px;
                @media screen and (max-width: $laptop-breakpoint) {
                    margin-top: -46px;
                    @media screen and (max-width: $tablet-breakpoint) {
                        margin-top: -42px;
                        @media screen and (max-width: $mobile-breakpoint) {
                            margin-top: -33px;
                        }
                    }
                }
                position: absolute;
                opacity: 0;
                /**
                Para modificar los parámetros de la animación, pensar en lo siguiente:
                --> Para una cantidad de 3 palabras de 3s cada una, la duración total de la
                animación sería de 9s:
                        9s = 100%
                        3s = 33.3% del tiempo total
                Lo anterior significa que cada palabra aparecerá dentro del margen del 33.3% del
                tiempo total. La palabra deberá aparecer utilizando un 10% de esa fracción, mantenerse
                un 80% más y desaparecer utilizando el 10% restante de tal fracción de tiempo.
                        100% = 3s
                        10% = (?) ... 0.3s --> 3.33% de la duración total
                        80% = (?) ... 2.4s --> 26.66% de la duración total
                        10% = (?) ... 0.3s --> 3.33% de la duración total
                Al final, los parámetros de la animación podrán modificarse ligeramente a gusto.
                 */
                $animation-duration-per-word: 3s;
                $words-count: 3;
                
                animation-name: word-spinning;
                animation-duration: $animation-duration-per-word * $words-count;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                &:nth-child(1) { animation-delay: $animation-duration-per-word * 0; }
                &:nth-child(2) { animation-delay: $animation-duration-per-word * 1; }
                &:nth-child(3) { animation-delay: $animation-duration-per-word * 2; }
            }
        }
    }
    .body-xl {
        @media screen and (min-width: $laptop-breakpoint) {
            max-width: 75%;
        }
    }
}

.image-toggler--list {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    overflow-y: hidden;
    overflow-x: hidden;
    @media screen and (max-width: $tablet-breakpoint) {
        overflow-x: auto;
        justify-content: flex-start;
    }
    .image-toggler__wrap {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        position: relative;
        margin: 0px 20px;
        .image-toggler--list-item {
            min-width: 1055px;
            max-width: 1055px;
            height: 100%;
            display: none;
            @media screen and (max-width: $desktop-breakpoint) {
                min-width: 780px;
                max-width: 780px;
            }
        }
        .section-label--in-captions {
            list-style-type: none;
            position: absolute;
        }
        .section-label--line {
            height: 1.5px;
            position: absolute;
            background-color: $primary-text-color-light;
        }
    }
}

.itl--for-domestic-services {
    .image-toggler--list-item {
        &:nth-of-type(1) {
            display: block;
        }
    }
    .section-label--in-captions {
        &:nth-of-type(1) { // CERCAS ELÉCTRICAS
            top: 20px;
            margin-left: 150px;
            @media screen and (max-width: $desktop-breakpoint) {
                top: 0px;
                margin-left: 110px;
            }
        }
        &:nth-of-type(2) { // CASAS INTELIGENTES
            left: 200px;
            top: 160px;
            @media screen and (max-width: $desktop-breakpoint) {
                left: 75px;
                top: 105px;
            }
        }
        &:nth-of-type(3) { // REDES INFORMÁTICAS
            right: 93px;
            top: 187px;
            @media screen and (max-width: $desktop-breakpoint) {
                right: 50px;
                top: 150px;
            }
        }
        &:nth-of-type(4) { // SISTEMAS DE ALARMA
            left: 7px;
            top: 277px;
            @media screen and (max-width: $desktop-breakpoint) {
                left: -15px;
                top: 200px;
            }
        }
        &:nth-of-type(5) { // SISTEMAS DE VIDEOVIGILANCIA
            right: -60px;
            margin-top: 100px;
            @media screen and (max-width: $desktop-breakpoint) {
                right: -70px;
            }
        }
        &:nth-of-type(6) { // INSTALACIONES ELÉCTRICAS
            left: 50px;
            bottom: 130px;
            @media screen and (max-width: $desktop-breakpoint) {
                left: 20px;
                bottom: 90px;
            }
        }
        &:nth-of-type(7) { // CONTROL DE ACCESO
            bottom: 40px;
            right: 175px;
            @media screen and (max-width: $desktop-breakpoint) {
                right: 120px;
            }
        }
        &:nth-of-type(8) { // SISTEMAS DE AUTOMATIZACIÓN
            visibility: hidden;
        }
        &:nth-of-type(9) { // SALAS DE JUNTAS
            visibility: hidden;
        }
        &:nth-of-type(10) { // GESTIÓN VEHICULAR
            visibility: hidden;
        }
    }
    .section-label--line {
        &:nth-of-type(1) { // CERCAS ELÉCTRICAS
            width: 117px;
            top: 60px;
            left: 323px;
            rotate: 30deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 61px;
                top: 33px;
                left: 262px;
            }
        }
        &:nth-of-type(2) { // CASAS INTELIGENTES
            width: 126px;
            top: 201px;
            left: 377px;
            rotate: 30deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 125px;
                top: 152px;
                left: 228px;
            }
        }
        &:nth-of-type(3) { // REDES INFORMÁTICAS
            width: 140px;
            top: 252px;
            right: 263px;
            rotate: -45deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 83px;
                top: 195px;
                right: 205px;
            }
        }
        &:nth-of-type(4) { // SISTEMAS DE ALARMA
            width: 85px;
            top: 312px;
            left: 195px;
            rotate: 30deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 58px;
                top: 228px;
                left: 150px;
            }
        }
        &:nth-of-type(5) { // SISTEMAS DE VIDEOVIGILANCIA
            width: 54px;
            margin-top: 8px;
            right: 78px;
            rotate: 60deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 49px;
                margin-top: 14px;
                right: 52px;
            }
        }
        &:nth-of-type(6) { // INSTALACIONES ELÉCTRICAS
            width: 184px;
            bottom: 246px;
            left: 74px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 137px;
                bottom: 181px;
                left: 54px;
            }
        }
        &:nth-of-type(7) { // CONTROL DE ACCESO
            width: 211px;
            bottom: 168px;
            right: 162px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 139px;
                bottom: 131px;
                right: 130px;
            }
        }
        &:nth-of-type(8) { // SISTEMAS DE AUTOMATIZACIÓN
            visibility: hidden;
        }
        &:nth-of-type(9) { // SALAS DE JUNTAS
            visibility: hidden;
        }
        &:nth-of-type(10) { // GESTIÓN VEHICULAR
            visibility: hidden;
        }
    }
}

.itl--for-business-services {
    .image-toggler--list-item {
        &:nth-of-type(2) {
            display: block;
        }
    }
    .section-label--in-captions {
        &:nth-of-type(1) { // CERCAS ELÉCTRICAS
            left: 0px;
            top: 190px;
            @media screen and (max-width: $desktop-breakpoint) {
                left: -10px;
                top: 135px;
            }
        }
        &:nth-of-type(2) { // CASAS INTELIGENTES
            visibility: hidden;
        }
        &:nth-of-type(3) { // REDES INFORMÁTICAS
            left: 30px;
            bottom: 120px;
            @media screen and (max-width: $desktop-breakpoint) {
                left: 10px;
                bottom: 75px;
            }
        }
        &:nth-of-type(4) { // SISTEMAS DE ALARMA
            top: 47px;
            left: 141px;
            @media screen and (max-width: $desktop-breakpoint) {
                top: 27px;
                left: 61px;
            }
        }
        &:nth-of-type(5) { // SISTEMAS DE VIDEOVIGILANCIA
            top: 142px;
            right: 12px;
            text-align: center;
            @media screen and (max-width: $desktop-breakpoint) {
                top: 224px;
                right: -104px;
                text-align: start;
            }
        }
        &:nth-of-type(6) { // INSTALACIONES ELÉCTRICAS
            top: 0px;
            margin-left: 370px;
            @media screen and (max-width: $desktop-breakpoint) {
                margin-left: 255px;
            }
        }
        &:nth-of-type(7) { // CONTROL DE ACCESO
            bottom: 35px;
            right: 360px;
            @media screen and (max-width: $desktop-breakpoint) {
                bottom: 21px;
                right: 258px;
            }
        }
        &:nth-of-type(8) { // SISTEMAS DE AUTOMATIZACIÓN
            visibility: visible;
            top: 95px;
            right: 156px;
            @media screen and (max-width: $desktop-breakpoint) {
                top: 74px;
                right: 84px;
            }
        }
        &:nth-of-type(9) { // SALAS DE JUNTAS
            visibility: visible;
            bottom: 0px;
            left: 240px;
            @media screen and (max-width: $desktop-breakpoint) {
                left: 165px;
            }
        }
        &:nth-of-type(10) { // GESTIÓN VEHICULAR
            visibility: visible;
            bottom: 135px;
            right: 75px;
            @media screen and (max-width: $desktop-breakpoint) {
                bottom: 100px;
                right: 45px;
            }
        }
    }
    .section-label--line {
        &:nth-of-type(1) { // CERCAS ELÉCTRICAS
            width: 53px;
            top: 238px;
            left: 59px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 40px;
                top: 175px;
                left: 44px;
            }
        }
        &:nth-of-type(2) { // CASAS INTELIGENTES
            visibility: hidden;
        }
        &:nth-of-type(3) { // REDES INFORMÁTICAS
            width: 166px;
            bottom: 224px;
            left: 37px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 131px;
                bottom: 161px;
                left: 22px;
            }
        }
        &:nth-of-type(4) { // SISTEMAS DE ALARMA
            width: 119px;
            top: 90px;
            left: 325px;
            rotate: 30deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 108px;
                top: 64px;
                left: 221px;
            }
        }
        &:nth-of-type(5) { // SISTEMAS DE VIDEOVIGILANCIA
            width: 98px;
            top: 233px;
            right: 43px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 38px;
                top: 232px;
                right: 30px;
                rotate: 30deg;
            }
        }
        &:nth-of-type(6) { // INSTALACIONES ELÉCTRICAS
            width: 227px;
            top: 135px;
            left: 367px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 160px;
                top: 103px;
                left: 275px;
            }
        }
        &:nth-of-type(7) { // CONTROL DE ACCESO
            width: 143px;
            bottom: 128px;
            right: 385px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 102px;
                bottom: 95px;
                right: 289px;
            }
        }
        &:nth-of-type(8) { // SISTEMAS DE AUTOMATIZACIÓN
            visibility: visible;
            width: 111px;
            top: 146px;
            right: 411px;
            rotate: -45deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 73px;
                top: 112px;
                right: 311px;
            }
        }
        &:nth-of-type(9) { // SALAS DE JUNTAS
            visibility: visible;
            width: 567px;
            bottom: 306px;
            left: 28px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 412px;
                bottom: 228px;
                left: 25px;
            }
        }
        &:nth-of-type(10) { // GESTIÓN VEHICULAR
            visibility: visible;
            width: 169px;
            bottom: 242px;
            right: 82px;
            rotate: 90deg;
            @media screen and (max-width: $desktop-breakpoint) {
                width: 123px;
                bottom: 184px;
                right: 65px;
            }
        }
    }
}

.section--services {
    .decoration-11 {
        width: 26px;
        position: absolute;
        margin-top: 20px;
        margin-left: -100px;
        @media screen and (max-width: $laptop-breakpoint) {
            display: none;
        }
    }
    .decoration-8 {
        height: 15px;
        position: absolute;
        right: 30px;
        top: 40px;
        @media screen and (min-width: $tablet-breakpoint) {
            display: none;
        }
    }
    .service-selector {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        margin-top: 15px;
        margin-bottom: 50px;
        .service-selector--item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding: 6px 14px;
            border: none;
            outline: inherit;
            background-color: $faint-gray-color;
            border-left: 1px solid $intense-gray-color;
            cursor: pointer;
            &:first-of-type {
                border-radius: 30px 0px 0px 30px;
                border-left: none;
            }
            &:last-of-type {
                border-radius: 0px 30px 30px 0px;
            }
            .body-2 {
                font-weight: 600;
                overflow: hidden;
                white-space: nowrap;
            }
            .material-symbols-outlined {
                margin-right: 5px;
                font-size: 23px;
            }
        }
        .service-selector--selected-item {
            background-color: rgba($secondary-color, 0.1);
            * {
                color: $secondary-color;
            }
        }
    }
    .headline-2 {
        &:nth-of-type(1) {
            margin-top: 80px;
            br {
                @media screen and (max-width: $tablet-breakpoint) {
                    display: none;
                }
            }
        }
        &:nth-of-type(2) {
            text-align: center;
            position: relative;
            .sparkle {
                width: 21px;
                position: absolute;
                &:nth-of-type(1) {
                    margin-left: 7px;
                }
                &:nth-of-type(2) {
                    margin-left: 14px;
                    margin-top: 17px;
                }
            }
        }
        .decoration-6 {
            height: 170px;
            rotate: 90deg;
            position: absolute;
            top: -170px;
            left: 17px;
            @media screen and (min-width: $tablet-breakpoint) {
                display: none;
            }
        }
    }
    .body-xl {
        &:nth-of-type(1){
            max-width: 60%;
            margin-top: 15px;
            @media screen and (max-width: $tablet-breakpoint) {
                max-width: 100%;
            }
        }
        &:nth-of-type(2) {
            margin-top: 15px;
            text-align: center;
        }
    }
    .c2a--services {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-top: 45px;
        margin-bottom: 170px;
        @media screen and (max-width: $mobile-breakpoint) {
            flex-flow: column nowrap;
            justify-content: center;
        }
        .mdc-fab {
            margin-right: 10px;
            @media screen and (max-width: $mobile-breakpoint) {
                margin-right: 0px;
                margin-bottom: 15px;
            }
        }
        .decoration-32 {
            width: 300px;
            position: absolute;
            right: 10%;
            @media screen and (max-width: $laptop-breakpoint) {
                right: 5%;
                @media screen and (max-width: $tablet-breakpoint) {
                    display: none;
                }
            }
        }
    }
}

.in-house-solutions {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 80px;
    @media screen and (max-width: $laptop-breakpoint) {
        flex-flow: column nowrap;
        margin-top: 40px;
        margin-bottom: 60px;
    }
    .decoration-7 {
        width: 40px;
        margin: 0px 10px;
    }
    .ihs--arrows-left,
    .ihs--arrows-right {
        display: flex;
        flex-flow: row nowrap;
    }
    .ihs--arrows-left {
        margin-right: 35px;
        @media screen and (max-width: $laptop-breakpoint) {
            display: none;
        }
    }
    .ihs--arrows-right {
        margin-left: 35px;
        .decoration-7 {
            rotate: -180deg;
        }
        @media screen and (max-width: $laptop-breakpoint) {
            margin: 0px;
            margin-top: 45px;
            .decoration-7 {
                width: 37px;
                margin: 0px 27px;
                rotate: -90deg;
            }
        }
    }
    .ihs--carousel {
        width: auto;
        max-height: 200px;
        display: flex;
        flex-flow: row nowrap;
        @media screen and (max-width: 560px) {
            width: 100%;
            padding: 0px 20px;
            overflow-x: auto;
        }
        .ihs--carousel-item {
            width: 100%;
            min-width: 260px;
            max-width: 260px;
            height: 100%;
            &:nth-of-type(1) {
                margin-right: 25px;
            }
            img {
                width: 60px;
                height: 60px;
                border-radius: 15px;
                border: 1px solid $intense-gray-color;
            }
            h4 {
                width: 100%;
                margin-top: 9px;
                font-family: 'Montserrat', sans-serif;
            }
            p {
                margin-top: 8px;
            }
        }
    }
}

.section--testimonials-header {
    .section-label--in-captions {
        text-align: center;
    }
    .decoration-12 {
        width: 130px;
        margin: 40px 0px;
        @media screen and (max-width: $tablet-breakpoint) {
            width: 100px;
        }
    }
    .headline-2 {
        br {
            @media screen and (max-width: $tablet-breakpoint) {
                display: none;
            }
        }
    }
}

.section--testimonials-newsletter {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .headline-3 {
        text-align: center;
        margin-bottom: 15px;
        @media screen and (max-width: $mobile-breakpoint) {
            text-align: left;
        }
        .decoration-5 {
            position: absolute;
            width: 55px;
            right: 4%;
            top: -170px;
            @media screen and (max-width: 1415px) {
                width: 47px;
                rotate: -90deg;
                right: 40px;
                top: -140px;
            }
        }
    }
    .body-1 {
        text-align: center;
        @media screen and (max-width: $mobile-breakpoint) {
            text-align: left;
        }
        br {
            @media screen and (max-width: $tablet-breakpoint) {
                display: none;
            }
        }
    }
    .newsletter-input {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        margin-top: 30px;
        margin-bottom: 120px;
        justify-content: center;
        position: relative;
        .newsletter-input--form {
            width: 100%;
            max-width: 420px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: relative;
            .mdc-text-field {
                width: 100%;
            }
            .mdc-text-field--outlined {
                padding-right: 120px;
            }
            button {
                position: absolute;
                right: 5px;
            }
        }
        .decoration-8 {
            position: absolute;
            width: 65px;
            left: -75px;
            bottom: -40px;
            @media screen and (max-width: $laptop-breakpoint) {
                display: none;
            }
        }
    }
    .illustration-city {
        width: 100%;
        @media screen and (max-width: $tablet-breakpoint) {
            width: 130%;
            margin-left: 27%;
            @media screen and (max-width: $mobile-breakpoint) {
                width: 250%;
                margin-left: 120%;
            }
        }
    }
}

.testimonials-carousel {
    margin-top: 50px;
    margin-bottom: 85px;
    @media screen and (max-width: 1415px) {
        margin-bottom: 145px;
    }
}

.section--contact {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px;
    .headline-2 {
        margin-top: 50px;
        margin-bottom: 20px;
        text-align: center;
    }
    .body-xl {
        text-align: center;
    }
    .contact-cards {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        @media screen and (max-width: $tablet-breakpoint) {
            flex-flow: column nowrap;
        }
        .cards-decoration {
            display: flex;
            flex-flow: column nowrap;
            margin-right: 4%;
            @media screen and (max-width: $laptop-breakpoint) {
                margin-right: 2%;
                @media screen and (max-width: $tablet-breakpoint) {
                    margin-right: 0px;
                    margin-bottom: 25px;
                }
            }
            .decoration-13 {
                width: 285px;
                @media screen and (max-width: $laptop-breakpoint) {
                    width: 260px;
                }
            }
            .decoration-14 {
                width: 180px;
                margin-top: 15px;
                margin-left: 90px;
                @media screen and (max-width: $laptop-breakpoint) {
                    width: 160px;
                    @media screen and (max-width: $tablet-breakpoint) {
                        display: none;
                    }
                }
            }
        }
        .contact-cards--item {
            min-width: 260px;
            max-width: 260px;
            display: flex;
            flex-flow: column nowrap;
            margin-left: 15px;
            @media screen and (max-width: $laptop-breakpoint) {
                min-width: 210px;
                max-width: 210px;
                @media screen and (max-width: $tablet-breakpoint) {
                    min-width: auto;
                    width: 100%;
                    max-width: 300px;
                    margin-left: 0px;
                    margin-top: 15px;
                }
            }
            span[class="material-symbols-outlined"] {
                font-size: 30px;
                color: initial;
            }
            h6 {
                font-weight: bold;
                margin-top: 45px;
            }
            p {
                margin-top: 8px;
                margin-bottom: 15px;
            }
            span[class="body-2"] {
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }
}

@keyframes word-spinning {
    0%   { opacity: 0; }                               // <-- Estado inicial
    1%   { opacity: 0; transform: translateY(-15px); } // <-- Traslación a punto de partida
    // Periodo en que aparece
    3%   { opacity: 1; transform: translateY(0px); }   // <-- Inicio de aparición y efecto de traslación hacia dentro (importe)
    // Periodo en que se mantiene
    29%  { opacity: 1; transform: translateY(0px); }
    // Periodo en que desaparece
    32%  { opacity: 0; transform: translateY(15px); }  // <-- Finalización de aparición y efecto de traslación hacia fuera (descarte)
    33%  { opacity: 0; }
    100% { opacity: 0; }                               // <-- Volviendo a estado inicial
}