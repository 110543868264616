// Material design components
// ...theme
@use "@material/theme" with (
    // + https://www.npmjs.com/package/@material/theme
  $primary: #08a18f,
  $secondary: #c4eee7,
  $on-primary: #ffffff,
  $on-secondary: #212121
);
// ...typhography
@use "@material/typography" with (
    // + https://www.npmjs.com/package/@material/typography#style-values
    $font-family: unquote("Nunito Sans, sans-serif"),
    $styles-button: (
        font-size: 14px,
        font-weight: 600,
        text-transform: none,
        letter-spacing: 0px
    )
);
// ...ripple
@use "@material/ripple";                                // <-- namespace para mixins
@use "@material/ripple/mdc-ripple";
// ...button
@use "@material/button";                                // <-- namespace para mixins
@use "@material/button/mdc-button";
// ...floating action button
@use "@material/fab";                                   // <-- namespace para mixins
// ...icon button
@use "@material/icon-button/mdc-icon-button";
@use "@material/icon-button";                           // <-- namespace para mixins
// ...textfield
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";                             // <-- namespace para mixins
// ...chips
@use "@material/chips";                                 // <-- namespace para mixins
@use "@material/chips/styles";
// ...menu-surface
@use "@material/list/mdc-list";
@use "@material/menu-surface/mdc-menu-surface";
@use "@material/menu/mdc-menu";
@use "@material/list";                                  // <-- namespace para mixins
@use "@material/menu-surface";                          // <-- namespace para mixins
@use "@material/menu";                                  // <-- namespace para mixins
// ...layout grid
@use "@material/layout-grid/mdc-layout-grid";
// ...checkbox
@use "@material/checkbox";
@use "@material/form-field";

@include fab.core-styles;
@include textfield.core-styles;
@include checkbox.core-styles;
@include form-field.core-styles;
// Theme de @pssatech
@import '@pssatech/squanchy-css/base/main';

// * {
//     background-color: aqua;
//     * {
//         background-color: chartreuse;
//         * {
//             background-color: darkviolet;
//             * {
//                 background-color: orange;
//                 * {
//                     background-color: blue;
//                     * {
//                         background-color: magenta;
//                         * {
//                             background-color: yellow;
//                             * {
//                                 background-color: green;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

// ========================================================================================== //
// Clases globales ========================================================================== //
// ========================================================================================== //

html, body {
    overflow-x: hidden;
}

// @reset
button,
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  background-color: transparent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.header-toolbar {
    border-bottom: 1px solid #DCDCDC;
    @media screen and (min-width: $desktop-breakpoint) {
        padding: 0px 70px;
    }
    a {
        img {
            width: $pssatech-logo-width;
            height: $pssatech-logo-height;
        }
    }
}

.commons--standard-container {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    padding: 0px 13%;
    @media screen and (max-width: $laptop-breakpoint) {
        padding-left: 5%;
        padding-right: 5%;
        @media screen and (max-width: $mobile-breakpoint) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.commons--first-of-type {
    padding-top: 40px;
    margin-top: $header-toolbar-height;
}

.commons--last-of-type {
    padding-bottom: 60px;
}

.headline-xl {
    @extend .headline-1;
    @media screen and (min-width: $laptop-breakpoint) {
        font-size: 60px;
        line-height: 80px;
    }
    @media screen and (max-width: $mobile-breakpoint) {
        font-size: 30px;
        line-height: 45px;
    }
}

.body-xl {
    @extend .body-1;
    @media screen and (min-width: $laptop-breakpoint) {
        font-size: 21px;
        line-height: 30px;
    }
}

.section-label--in-captions {
    @extend .body-2;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (max-width: $desktop-breakpoint) {
        font-size: 13px;
    }
}

.testimonials-carousel {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    @media screen and (max-width: 1160px) {
        overflow-x: auto;
        justify-content: flex-start;
        padding: 0px 20px;
    }
    .testimonials-carousel--item {
        min-width: 270px;
        max-width: 270px;
        min-height: 220px;
        max-height: 220px;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        border-radius: 20px;
        background-color: $faint-gray-color;
        padding: 20px;
        padding-bottom: 30px;
        margin-right: 20px;
        @media screen and (max-width: $mobile-breakpoint) {
            min-width: 230px;
            max-width: 230px;
            min-height: auto;
            max-height: auto;
        }
        &:last-of-type {
            margin-right: 0px;
        }
        &::before {
            content: '';
            width: 100%;
            height: 100%;    
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(transparent 67%, $faint-gray-color 85%);
            border-radius: 20px;
        }
        img {
            width: 45px;
            height: 45px;
            border-radius: 100px;
        }
        h6 {
            margin-top: 15px;
            margin-bottom: 6px;
            font-weight: bold;
        }
        p {
            overflow: hidden;
        }
        .testimonial-ranking {
            display: flex;
            flex-flow: row nowrap;
            position: absolute;
            right: 20px;
            top: 45px;
            span {
                font-size: 18px;
            }
        }
    }
}

.link-card {
    display: flex;
    flex-flow: row wrap;
    border-radius: 20px;
    border: 1px solid $intense-gray-color;
    padding: 15px;
    text-decoration: none;
    &:hover {
        background-color: $faint-gray-color;
    }
    &:active {
        background-color: $faint-gray-color;
    }
}

.budget-call-to-action {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    p {
        width: 100%;
        text-align: center;
        padding: 0px 20px;
    }
    .mdc-fab {
        margin-top: 20px;
    }
}

.project-tags--carousel {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    .project-tags--item {
        @extend .support-2;
        font-weight: bold;
        margin-right: 12px;
        border: 2px solid $primary-text-color-light;
        border-radius: 100px;
        white-space: nowrap;
        padding: 6px 12px;
    }
}

.image-visualizer {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    position: fixed;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 5%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: rgba(black, 0.8);
    z-index: 10;
    .image-visualizer--exit-action {
        position: absolute;
        left: 20px;
        top: 20px;
        @media screen and (max-width: $tablet-breakpoint) {
            left: 10px;
            top: 10px;
        }
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.standard-documentation-page {
    header {
        justify-content: space-between;
        .header--reading-contents {
            text-align: right;
            p {
                @media screen and (max-width: 320px) {
                    width: 100%;
                    max-width: 110px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }
    .commons--first-of-type {
        .decoration-29 {
            width: 200px;
            align-self: center;
            @media screen and (max-width: $tablet-breakpoint) {
                width: 155px;
            }
        }
        .section-label--in-captions {
            text-align: center;
            margin-top: 80px;
            margin-bottom: 20px;
            @media screen and (max-width: $tablet-breakpoint) {
                margin-top: 60px;
            }
        }
        h1, .project-description {
            width: 100%;
            max-width: 700px;
            text-align: center;
            align-self: center;
        }
        .project-description {
            @media screen and (max-width: $tablet-breakpoint) {
                text-align: left;
            }
        }
        .project-tags--carousel {
            justify-content: center;
            margin-top: 30px;
            margin-bottom: 50px;
            @media screen and (max-width: $tablet-breakpoint) {
                justify-content: flex-start;
            }
        }
    }
    .project-main-container {
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        align-content: center;
        padding: 0px 20px;
        position: relative;
        .project--expandable-image {
            cursor: pointer;
        }
        .headline-3 {
            width: 100%;
            max-width: 800px;
            align-self: center;
            margin-top: 40px;
        }
        .body-1 {
            max-width: 800px;
            align-self: center;
            margin: 35px 0px;
            @media screen and (max-width: $tablet-breakpoint) {
                margin: 25px 0px;
            }
        }
        .project--image {
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            margin: 15px 0px;
            img {
                width: 65%;
                object-fit: cover;
            }
            .support-1 {
                width: 65%;
                margin-top: 10px;
            }
        }
        .project--image-cover {
            max-width: 900px;
            flex-flow: row wrap;
            align-self: center;
            margin: 0px;
            iframe, img {
                width: 100%;
                min-height: 480px;
                max-height: 480px;
                @media screen and (max-width: $tablet-breakpoint) {
                    min-height: 380px;
                    max-height: 380px;
                    @media screen and (max-width: $mobile-breakpoint) {
                        min-height: 210px;
                        max-height: 210px;
                    }
                }
            }
            .support-1 {
                width: 100%;
                text-align: center;
            }
        }
        .project--image-in-left {
            padding-left: 10%;
            align-items: flex-start;
            @media screen and (max-width: $laptop-breakpoint) {
                padding-left: 40px;
                img {
                    width: 90%;
                }
                .support-1 {
                    width: 90%;
                }
                @media screen and (max-width: $tablet-breakpoint) {
                    padding-left: 0px;
                }
            }
        }
        .project--image-in-right {
            padding-right: 10%;
            align-items: flex-end;
            @media screen and (max-width: $laptop-breakpoint) {
                padding-right: 40px;
                img {
                    width: 90%;
                }
                .support-1 {
                    width: 90%;
                }
                @media screen and (max-width: $tablet-breakpoint) {
                    padding-right: 0px;
                }
            }
        }
        .project--small-image {
            margin: 40px 0px;
            @media screen and (max-width: $tablet-breakpoint) {
                margin: 20px 0px;
            }
            img {
                width: 40%;
                max-height: 400px;
                align-self: center;
                @media screen and (max-width: $laptop-breakpoint) {
                    width: 60%;
                    max-height: 600px;
                    @media screen and (max-width: $tablet-breakpoint) {
                        width: 80%;
                        max-height: fit-content;
                    }
                }
            }
            .support-1 {
                width: 100%;
                text-align: center;
            }
        }
        .project--small-large-image {
            margin: 40px 0px;
            @media screen and (max-width: $tablet-breakpoint) {
                margin: 20px 0px;
            }
            img {
                width: 280px;
                max-height: 400px;
                align-self: center;
                @media screen and (max-width: $tablet-breakpoint) {
                    width: 200px;
                }
            }
            .support-1 {
                width: 100%;
                text-align: center;
            }
        }
        .project--two-images-in-row {
            flex-flow: row wrap;
            justify-content: center;
            img {
                width: 100%;
                max-width: 430px;
                max-height: 360px;
                object-fit: cover;
                @media screen and (max-width: $laptop-breakpoint) {
                    max-width: 330px;
                    @media screen and (max-width: $tablet-breakpoint) {
                        max-width: 210px;
                        @media screen and (max-width: $mobile-breakpoint) {
                            max-width: 80%;
                        }
                    }
                }
                &:first-of-type {
                    margin-right: 15px;
                    @media screen and (max-width: $mobile-breakpoint) {
                        margin-right: 0px;
                        margin-bottom: 10px;
                    }
                }
            }
            .support-1 {
                width: 100%;
                text-align: center;
            }
        }
        .mdc-layout-grid {
            width: 100%;
            max-width: 900px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .mdc-layout-grid--label {
            width: 100%;
            text-align: center;
        }
        .mdc-layout-grid--large-variant {
            img {
                min-height: 200px;
                max-height: 400px;
            }
        }
        .mdc-layout-grid--short-variant {
            img {
                min-height: 200px;
                max-height: 200px;
            }
        }
        .four-boxes-decoration {
            margin-top: 80px;
            margin-bottom: 50px;
        }
        .budget-call-to-action {
            margin-bottom: 70px;
        }
        .project--gallery {
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            margin: 20px 0px;
            padding: 0px 10%;
            @media screen and (max-width: $laptop-breakpoint) {
                padding: 0px 5%;
                @media screen and (max-width: $tablet-breakpoint) {
                    padding: 0px;
                }
            }
            .headline-3 {
                margin: 0px;
                margin-bottom: 50px;
                text-align: center;
            }
            .gallery-images {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                overflow-x: auto;
                img {
                    min-height: 250px;
                    max-height: 250px;
                    object-fit: cover;
                    margin-right: 15px;
                }
            }
        }
        .budget-call-to-action {
            p {
                padding: 0px;
            }
        }
    }
}

.banner-for-app-remote {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $laptop-breakpoint) {
        flex-flow: column nowrap;
    }
    .banner-decorations--left,
    .banner-decorations--right {
        .decoration-7 {
            width: 37px;
            margin: 0px 10px;
        }
    }
    .banner-decorations--left {
        @media screen and (max-width: $laptop-breakpoint) {
            display: none;
        }
    }
    .banner-decorations--right {
        .decoration-7 {
            rotate: -180deg;
            @media screen and (max-width: $laptop-breakpoint) {
                rotate: 270deg;
                margin: 0px 30px;
                @media screen and (max-width: $mobile-breakpoint) {
                    margin: 0px 25px;
                }
            }
        }
    }
    .banner-box-content {
        max-width: 500px;
        height: 110px;
        flex-flow: row nowrap;
        margin: 0px 35px;
        @media screen and (max-width: $laptop-breakpoint) {
            margin: 0px;
            margin-bottom: 20px;
            @media screen and (max-width: $mobile-breakpoint) {
                flex-flow: row wrap;
                height: fit-content;
            }
        }
        img {
            width: 60px;
            height: 60px;
            border-radius: 15px;
            border: 1px solid $intense-gray-color;
            align-self: center;
        }
        h4 {
            font-family: 'Montserrat', sans-serif;
            align-self: center;
            margin: 0px 15px;
        }
        .line-separator {
            width: 1px;
            height: 100%;
            background-color: $intense-gray-color;
            @media screen and (max-width: $mobile-breakpoint) {
                display: none;
            }
        }
        p {
            padding-left: 15px;
            height: fit-content;
            align-self: center;
            @media screen and (max-width: $mobile-breakpoint) {
                padding-left: 0px;
                padding-top: 15px;
            }
        }
    }
}

.four-boxes-decoration {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    svg {
        width: 45px;
        @media screen and (max-width: $mobile-breakpoint) {
            width: 35px;
        }
    }
    .decoration-17 {
        path {
            fill: $primary-text-color-light;
        }
    }
}

// ========================================================================================== //
// Theme de componentes de MDC para web ===================================================== //
// ========================================================================================== //
.material-symbols-outlined--filled {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
}

// @override
.mdc-button {
    @include button.shape-radius($level-2-border-radius);
    @include button.horizontal-padding(16px);
    .mdc-button__label {
        white-space: nowrap;
    }
}

// @override
.mdc-text-field {
    @include textfield.outline-shape-radius($level-2-border-radius);
}

// @override
.mdc-fab--extended {
    @include fab.accessible($secondary-color);
}

// @override
.mdc-evolution-chip-set {
    @include chips.vertical-space-between-chips(0px);
}

// @override
.mdc-evolution-chip {
    @include chips.selected-container-color(rgba($secondary-color, 0.1));
    @include chips.selected-text-label-color($secondary-color);
    @include chips.checkmark-color($secondary-color);
    @include chips.horizontal-padding(14px, 14px);
}

.mdc-evolution-chip--high-emphasis {
    @include chips.container-color(rgba($secondary-color, 0.1));
}

// @override
.mdc-menu-surface {
    @include menu-surface.shape-radius(20px);
}

// @override
.mdc-deprecated-list {
    @include list.deprecated-icon-margin(15px);
    .mdc-deprecated-list-item {
        @include list.deprecated-list-item-padding(16px);
        // @include ripple.states-base-color(($secondary-color));
        // @include ripple.states-opacities((hover: 1.0, focus: 0.1, press: 0.1));
        .mdc-deprecated-list-item__text {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            .material-symbols-outlined {
                font-size: 22px;
                padding-right: 14px;
            }
        }
    }
}

// @override
.mdc-checkbox {
    @include checkbox.container-colors($primary-text-color-light, transparent, $secondary-color, $secondary-color);
    @include checkbox.ink-color(white);
}

.mdc-icon-button--white-version {
    @include icon-button.ink-color(white);
}